import { render, staticRenderFns } from "./exameIn.vue?vue&type=template&id=52da4381&scoped=true&"
import script from "./exameIn.vue?vue&type=script&lang=js&"
export * from "./exameIn.vue?vue&type=script&lang=js&"
import style0 from "./exameIn.vue?vue&type=style&index=0&lang=css&"
import style1 from "./exameIn.vue?vue&type=style&index=1&id=52da4381&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52da4381",
  null
  
)

export default component.exports