<template>
	<div class="system-content">
		<div class="top-box">
			<p class="title">{{courseName}}</p>
			<div class="progress-box">
				<div class="progress-inner" :style="'width:'+ 100/questionList.length*(progressIndex+1) +'%;'">
					<p class="progress-inner-text">{{progressIndex}}/{{questionList.length}}</p>
				</div>
			</div>
			<p class="timeString">剩余时间：{{timeString}}</p>
		</div>
		<div class="bottom-box p1">
			<p class="title1">{{current+1}}.{{question.quesType == 1 ? '单选题' : '多选题'}}({{question.score}}分)</p>
			<p class="title2">{{question.quesContent}}</p>
			<img @click="visible = true" class="image" v-if="question.sysAttachFileList.length != 0" :src="question.sysAttachFileList.length == 0 ? '' : question.sysAttachFileList[0].fileAddr" alt="">
			<br>
			<!-- 单选框 -->
			 <el-radio-group v-if="question.quesType == 1" v-model="radioAnswer" @change="handleAnswer">
				<div style="margin-bottom:10px;" v-for="(item,i) in question.quesSels" :key="i">
					<el-radio :label="i+1" >{{item}}</el-radio>
				</div>
			</el-radio-group>
			<!-- 复选框 -->
			<el-checkbox-group v-if="question.quesType == 2" v-model="checkboxAnswer" @change="handleAnswer">
				<div style="margin-bottom:10px;" v-for="(item,i) in question.quesSels" :key="i">
					<el-checkbox :label="i+1">{{item}}</el-checkbox>
				</div>
			</el-checkbox-group>
			<div class="footer-box">
				<!-- <button class="btn1" @click="handleBack">返回</button> -->
				<button class="btn2" @click="handlPrev">上一题</button>
				<button class="btn2" @click="handleNext">下一题</button>
				<!-- <button class="btn2" v-if="current == questionList.length-1" @click="handleSubmit">交卷</button> -->
				<button class="btn2" @click="handleSubmit">交卷</button>
			</div>
		</div>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="600px">
			<span slot="title" class="dialog-title">
				<span>图片</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<img class="dialog-image" :src="question.sysAttachFileList.length == 0 ? '' : question.sysAttachFileList[0].fileAddr" alt="">
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			courseName: '',
			current: 0,
			question: {
				sysAttachFileList: []
			},
			radioAnswer: '',
			checkboxAnswer: [],
			questionList: [],
			answerList: [],
			answers: [],
			timer: null,
			progressIndex: 0,
			visible: false,
			timeString: ''
		}
	},
	mounted() {
		this.init();
	},
	destroyed() {
		clearInterval(this.timer);
	},
	methods: {
		init() {
			this.courseName = this.$route.query.courseName;
			let that = this;
			that.$request.post(
				"getExamList",
				true,
				{
					courseId: that.$route.query.courseId
				},
				function (r) {
					if (r.code == "0") {
						that.questionList = r.data;
						that.question = r.data[0];
						console.log(that.question)
						r.data.forEach(item => {
							// 单选题
							if(item.quesType == 1) {
								that.answerList.push('');
							}
							// 多选题
							else {
								that.answerList.push([]);
							}
						})
						that.handleExamTime();
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 倒计时
		handleExamTime() {
			let examTime = parseInt(window.sessionStorage.getItem('examTime'));
			this.timer = setInterval(() => {
				if(new Date().getTime() > examTime) {
					clearInterval(this.timer);
					this.handleSubmit();
				}else {
					let timeStr = window.sessionStorage.getItem('examTimeStr');
					let h = Math.floor(timeStr/60/60%24) > 9 ? Math.floor(timeStr/60/60%24) : '0'+Math.floor(timeStr/60/60%24);
					let m = Math.floor(timeStr/60%60) > 9 ? Math.floor(timeStr/60%60) : '0'+Math.floor(timeStr/60%60);
					let s = Math.floor(timeStr%60) > 9 ? Math.floor(timeStr%60) : '0'+Math.floor(timeStr%60);
					this.timeString = h + ':' + m + ':' + s;
					timeStr--;
					window.sessionStorage.setItem('examTimeStr',timeStr);
				}
			}, 1000);
		},
		// 计算进度条
		handleAnswer() {
			this.answerList[this.current] = this.question.quesType == 1 ? this.radioAnswer : this.checkboxAnswer;
			let progress = 0;
			this.answerList.forEach(item => {
				if(item == '' || item == []) {}
				else {
					progress++;
				}
			})
			this.progressIndex = progress;
		},
		// 返回
		handleBack() {
			clearInterval(this.timer);
			this.$router.go(-1);
		},
		// 上一题
		handlPrev() {
			if(this.current == 0) {
				return
			}else {
				this.current--;
				this.question = this.questionList[this.current];
				this.question.quesType == 1 ? this.radioAnswer = this.answerList[this.current] : this.checkboxAnswer = this.answerList[this.current];
			}
		},
		// 下一题
		handleNext() {
			if(this.current == this.questionList.length-1) {
				return
			}
			if(JSON.stringify(this.answerList[this.current]) == '""' || JSON.stringify(this.answerList[this.current]) == '[]'){
				this.$message.error('请答完本题，再进行下一题。');
			}else {
				this.radioAnswer = '';
				this.checkboxAnswer = [];
				this.current++;
				this.question = this.questionList[this.current];
				this.question.quesType == 1 ? this.radioAnswer = this.answerList[this.current] : this.checkboxAnswer = this.answerList[this.current];
			}
		},
		// 交卷
		handleSubmit() {
			let noAnswer = 0;
			this.answers = [];
			this.questionList.forEach((item,index) => {
				if(item.quesType == 1) {
					this.answers.push({
						quesId: item.id,
						answerSelSort: this.answerList[index]+''
					})
					if(this.answerList[index] == '') {
						noAnswer++;
					}
				}else {
					this.answers.push({
						quesId: item.id,
						answerSelSort: this.answerList[index].join(',')
					})
					if(this.answerList[index].length == 0) {
						noAnswer++;
					}
				}
			})
			let examTime = parseInt(window.sessionStorage.getItem('examTime'));
			if(new Date().getTime() > examTime) {
				this.$confirm('考试时间结束，是否提交试卷?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.handleSubmit1();
				}).catch(() => {
					this.$router.push({
						name: 'onlineExam'
					})
				});
				return
			}
			if(noAnswer > 0) {
				this.$confirm('还剩'+noAnswer+'题未作答，确认提交试卷?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.handleSubmit1();
				}).catch(() => {

				});
			}else {
				this.$confirm('确认提交试卷?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					this.handleSubmit1();
				}).catch(() => {

				});
			}
		},
		handleSubmit1() {
			let that = this;
			that.$request.post(
				"submitExam",
				true,
				{
					id: that.$route.query.examId,
					answerList: that.answers
				},
				function (r) {
					if (r.code == "0") {
						that.$router.push({
							name: 'examAfter',
							query: {
								examId: that.$route.query.examId,
								examinAmount: r.data.examinAmount,
								failAmount: r.data.failAmount,
								passFlag: r.data.passFlag,
								userScore: r.data.userScore
							}
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	box-sizing: border-box;
	padding-top: 20px;
	.top-box{
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		padding-bottom: 20px;
		.title{
			text-align: center;
			color: #333333;
			font-weight: bold;
			font-size: 20px;
		}
	}
	.bottom-box{
		background-color: #fff;
		border-radius: 10px;
		margin-top: 10px;
		box-sizing: border-box;
		.image{
			width: 100px;
			cursor: pointer;
		}
		.title1{
			line-height: 36px;
    		border-bottom: 1px solid #ddd;
    		margin-bottom: 10px;
		}
		.el-radio-group,
		.el-checkbox-group{
			margin-top: 20px;
		}
		// /deep/ .el-radio__inner,
		// /deep/ .el-checkbox__inner{
		// 	width: 24px;
		// 	height: 24px;
		// }
		// /deep/ .el-checkbox__inner::after{
		// 	height: 15px;
    	// 	left: 8px;
		// }
		.footer-box{
			margin-top: 100px;
			box-sizing: border-box;
			padding-top: 20px;
			border-top: 1px solid #ddd;
			text-align: center;
			button{
				margin-left: 15px;
				cursor: pointer;
				width: 71px;
				height: 27px;
				border: none;
				outline: none;
				border-radius: 3px;
				color: #fff;
			}
			.btn1{
				color: rgba(233, 81, 94, 1);
				background-color: rgba(233, 81, 94, 0.2);
			}
			.btn2{
				background: #117FFC;
			}
		}
	}
}
.progress-box{
	width: 80%;
	margin: 15px auto;
	height: 18px;
	border-radius: 100px;
    background-color: #EBEEF5;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
	.progress-inner{
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background-color: #409EFF;
		text-align: right;
		border-radius: 100px;
		line-height: 1;
		white-space: nowrap;
		transition: width .6s ease;
		.progress-inner-text{
			width: 20px;
			color: #FFF;
			font-size: 12px;
			margin: 0 auto;
			line-height: 18px;
		}
	}
}
.timeString{
	text-align: center;
	color: red;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.dialog-image{
	width: 100%;
}
</style>
